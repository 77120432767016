.banner-faq{
    background-color: $cor-azul;
    padding-bottom: 150px;
    position: relative;
    overflow: hidden;
    @include media-min992 {
        max-height: 590px;
        min-height: 60%;
        padding-bottom: 0;
    }
    @include media-min1200 { min-height: 70%; }
    .header-faq{
        padding-top:50px;
        z-index: 1;
        justify-content: center;
        @include media-min992 { justify-content: space-between; }
    }
    .logo-faq{
        img{
            max-width: 130px;
            @include media-min992 { max-width: 40%; }
        }
        p{
            font-family: 'hendersonsans-light';
            color: #FFFFFF;
            padding-left: 20px;
            margin-left: 20px;
            border-left: 1px solid #FFFFFF;
            margin-bottom: 0;
            white-space: nowrap;
            font-size: 12px;
            @include media-min992 { font-size: 16px; }
        }
    }

    &-info{
        text-align: center;
        margin-top: 60px;
        @include media-min992 { margin-top: 0; }
        .titulo{
            font-size: 22px;
            margin-bottom: 10px;
            @include media-min992 { font-size: 40px; margin-bottom: 15px; }
            @include media-min1200 { font-size: 50px; margin-bottom: 22px; }
        }
        p{
            font-size: 14px;
            @include media-min992 { font-size: 16px; }
            @include media-min1200 { font-size: 18px; } 
        }
    }

    .banner-faq-grafismo-esquerda,
    .banner-faq-grafismo-direita{
        position: absolute;
        z-index: 0;
    }
    .banner-faq-grafismo-esquerda{
        bottom: 0;
        left: -1vw;
        width: 17vw;
    }
    .banner-faq-grafismo-direita{
        bottom: 0;
        right: -10vw;
        width: 28vw;
    }
    .banner-faq-grafismo-mobile{
        position: absolute;
        bottom: 0;
        width: 992px;
        max-width: 992px;
        left: 50%;
        transform: translateX(-50%);
    }
}