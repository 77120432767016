.footer {

	&-store_mobile {
		.titulo {
			margin: 55px 0 35px;
		}
	}

	&-container {
		background-color: #323232;

		&_top {
			padding: 35px 0 49px;

			@include media-min992 {
				padding: 60px 0 60px;
			}
		}
		&_store {
			padding: 35px 0 10px;
			border-top: 1px solid rgba(112, 112, 112, 0.67);

			@include media-min992 {
				padding: 73px 0 30px;
			}
		}

		&_lista-social {
			display: flex;
			justify-content: center;
			margin-bottom: 0;
			margin-top: 26px;

			@include media-min992 {
				justify-content: flex-end;
				margin-top: 0px;
			}

			&_item {
				margin-right: 47px;

				@include media-min992 {
					margin-right: 72px;

					&:last-of-type {
						margin-right: 0;
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}

			&_link {
				font-size: 25px;
				color: #FFFFFF;
				transition: all 0.4s ease;

				@include media-min992 {
					font-size: 32px;
				}
				
				&:hover {
					color: darken(#FFFFFF, 20%);
				}
			}
		}

		&_lista-nav {
			margin-top: 40px;

			@include media-min992 {
				margin-top: 0;
			}
			&_item {
				margin-bottom: 30px;

				@include media-min992 {
					margin-bottom: 0;
				}

				&:nth-of-type(1) {
					@include media-min992 {
						margin-bottom: 29px;
					}
				}
			}

			&_link {
				font-size: 16px;
				line-height: 22px;
				color: #FFFFFF;
				transition: all 0.4s ease;

				&:hover {
					color: darken(#FFFFFF, 20%)
				}
			}
		}

		&_lista-news {
			text-align: center;
			margin-bottom: 0;
			justify-content: center;
			display: flex;
			flex-wrap: wrap;

			@include media-min768 {
				flex-wrap: inherit;
			}

			@include media-min992 {
				justify-content: inherit;
				text-align: left;
				flex-wrap: wrap;
			}

			&_link {
				color: #FFFFFF;
				transition: all 0.4s ease;

				&:hover {
					color: darken(#FFFFFF, 20%);
					transition: all 0.4s ease;
				}

				&-titulo {
					font-family: 'hendersonsans-bold', sans-serif;
					font-size: 14px;
					line-height: 17px;
				}

				&-texto {
					font-size: 12px;
					line-height: 14px;
					margin-bottom: 0;
				}
			}

			&_item {
				margin-bottom: 25px;
				margin-left: 7px;
				margin-right: 7px;
				@include media-min992 {
					&:nth-of-type(2) {
						margin-bottom: 32px;
					}
				}

				&:nth-of-type(2n + 1) {
					@include media-min992 {
						margin-right: 152px;
					}
				}
			}
		}
	}

	&-address {
		background-color: #262626;
		padding: 13px 0 13px;

		.container {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			align-items: center;

			@include media-min992 {
				flex-direction: row;
			}
		}

		&_container {
			display: flex;
			align-items: center;
			margin-bottom: 8px;

			@include media-min992 {
				margin-bottom: 0;
			}
		}

		&_texto {
			margin: 0;
			font-family: Arial, sans-serif;
			font-size: 10px;
			color: #FFFFFF;
		}
	}

	&_store {
		position: relative;
		display: flex;
		justify-content: flex-end;
		z-index: 2;
	}
}