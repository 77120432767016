/* ==========================================================================
   Botoes
   ========================================================================== */

   .btn {
      position: relative;
      padding: 14px 32px 11px;
      background-color: #AAAAAA;
      color: #5F5F5F;
      border-radius: 0;
      font-size: 16px;
      font-family: 'hendersonsans-bold', sans-serif;
      font-weight: normal;
      line-height: 26px;
      transition: all 0.4s ease;

      @include media-min992 {
         padding: 14px 42px 11px;
      }

      &:hover,
      &:focus {
         background-color: darken($color: #AAAAAA, $amount: 20%);
      }
   }

   // utilidades 

   .btn {
      &-block {
         display: block;
      }

      &-radius {
         border-radius: 50px;
      }

      &-store {
         // padding-left: 5px;
         // padding-right: 5px;
         padding: 9px 5px;
         img{ max-width: 80%; }
      }

      &-link {
         padding: 0;
         background: none;
         text-decoration: underline;

         &:hover,
         &:focus {
            background: none;
            color: inherit;
         }
      }

      &-voltar {
         background: none;
         border: none;
         margin-bottom: 32px;
         font-family: 'hendersonsans-bold', sans-serif;
         font-size: 12px;
         line-height: 18px;
         
         @include media-min768 {
            font-size: 18px;
            line-height: 26px;
         }
         
         img {
           margin-top: -4px;
           margin-right: 8px;
           max-width: 38px;
           
           @include media-min768 {
               margin-right: 16px;
               max-width: inherit;
           }
         }
       }
   }

   // cores

   .btn {
      &-amarelo {
         border: 2px solid $cor-btn-amarelo;
         background-color: $cor-btn-amarelo;
         color: #38382E;

         &:hover,
         &:focus {
            background-color: transparent;
            color: $cor-btn-amarelo;
         }

         &.no-hover {
            &:hover {
               background-color: $cor-btn-amarelo;
               color: #000000;
            }
         }
      }

      &-preto {
         background-color: $cor-btn-preto;
         border: 2px solid #FFF;
         color: white;
         
         &::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #FFF;
            border-radius: inherit;
            transition: all 0.4s ease;
            z-index: -1;
         }
         
         &:hover,
         &:focus {
            background-color: #000000;
            color: white;

            &::before {
               width: calc(100% + 14px);
               height: calc(100% + 14px);
            }
         }
      }
   }