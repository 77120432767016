@keyframes slide-in {
  to {
    transform: translate(0,0);
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes role-seta {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(5px);
  }
}

@keyframes modal-out {
  90% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
    display: none;
  }
}

.fade-out {
  opacity: 1 !important;
  display: block !important;
  animation: fade-out 0.4s ease forwards;
}

[data-anime] {
  opacity: 0;
  transition: all 0.6s ease;
}

[data-anime="left"] {
  transform: translate3d(-200px, 0, 0);
}

[data-anime="right"] {
  transform: translate3d(200px, 0, 0);
}

[data-anime="top"] {
  transform: translate3d(0, -200px, 0);
}

[data-anime="bottom"] {
  transform: translate3d(0, 200px, 0);
}

[data-anime].animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}