.card-parceiro {
  margin-bottom: 16px;
  position: relative;
  overflow: hidden;
  padding: 30px 25px;
  width: 100%;
  height: 286px;
  
  @include media-min992 {
    margin-bottom: 30px;
    height: 450px;
  }

  &_img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    transition: all 0.6s ease;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.9);
      background: -moz-linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
      background: -webkit-linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
      background: linear-gradient(0deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
  }

  &_conteudo {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 270px;
  }

  &_texto {
    margin-top: 6px;
    margin-bottom: 27px;
    font-family: 'hendersonsans-light', sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #FFF;
  }

  &_logo {
    align-self: flex-start;
    max-height: 20px;
  }

  &_link {
    &:hover {
      .card-parceiro_img {
        transform: scale(1.2);
      }
    }
  }

  // tamanhos
  &_lg {
    .card-parceiro_conteudo { max-width: 375px; }

    .card-parceiro_img {
      &::before {

        background: rgba(0,0,0,0.9);
        background: -moz-linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
        background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
        background: linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);

        @include media-min992 {
          background: rgba(0,0,0,0.9);
          background: -moz-linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
          background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
          background: linear-gradient(90deg, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 78%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
      }
    }

  }
}

.card-duvida {
  margin-right: 16px;
  padding: 20px;
  display: flex;
  min-width: 280px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  box-shadow: 5px 5px 30px 0 rgba(0,0,0,0.08);
  
  @include media-min768 {
    padding: 40px;
    border-radius: 5px;
  }
  
  @include media-min992 {
    margin: 0 15px 18px;
    padding: 50px 79px;
    width: 100%;
    max-width: 1170px;
    height: auto;
    flex-direction: row;
    align-items: center;
  }

  @include media-min1200 {
    margin-left: auto;
    margin-right: auto;
  }

  &_container {
    .owl-item {
      height: auto;
    }

    .owl-stage {
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 40px;
    }
  }

  &_header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    @include media-min992 {
      margin-bottom: 0;
    }
  }

  &_img {
    position: relative;
    width: 63px;
    height: 63px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    
    @include media-min992 {
      margin-right: 50px;
      width: 146px;
      height: 150px;
    }

    .circle,
    .square {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &_conteudo {
    flex: 1;

    .titulo {
      margin-bottom: 12px;
      color: #000000;
    }
  }

  &_texto {
    margin-bottom: 12px;
    color: #717171;
    font-size: 14px;
    line-height: 22px;
    @include media-min992 {
      font-size: 16px;
    }
  }

  &_pessoa {
    margin-left: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    
    @include media-min992 {
      width: 100%;
      align-items: flex-end;
      flex: auto;
      margin-left: 0;
    }

    p { margin: 0; }
  }

  &_nome {
    color: #000;
  }

  &_equipe {
    font-family: 'hendersonsans-light', sans-serif;
    font-size: 12px;
    color: #B1B1B1;
  }
}