.form {
	button[type="submit"] {
		margin-top: 55px;
	}

	.btn-amarelo:hover {
		color: inherit;
	}
}

.input {
	width: 100%;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #000000;
	padding: 12px 0;
	font-size: 16px;
	line-height: 24px;
	transition: all 0.3s ease;

	&::placeholder {
		font-size: 16px;
		line-height: 24px;
		color: #B8B8B8;
	}

	&:focus {
		outline: none;
		border-bottom: 1px solid #5982FF;
	}
}

.label-input {
	display: block;
	font-family: 'hendersonsans-bold', sans-serif;
	font-size: 16px;
	line-height: 23px;
	color: #000000;
	margin-bottom: 8px;
}


.select2-container--default {
	.select2-selection--single {
		padding: 12px 0;
		height: auto;
		background-color: transparent;
		border: none;
		border-bottom: 1px solid #000000;
		border-radius: 0;

		&:focus {
			outline: none;
			border-bottom: 1px solid #5982FF;
		}

		.select2-selection__arrow {
			height: 48px;
			b {
				@extend .fas;
				@extend	.fa-chevron-down;
				border: none;
				color: $cor-azul;
				font-size: 18px;
			}
		}

		&[aria-expanded="true"] {
			.select2-selection__arrow {
				b {
					@extend	.fa-chevron-up;
				}
			}
		}

		&[aria-expanded="false"] {
			.select2-selection__arrow {
				b {
				}
			}
		}

		.select2-selection__rendered {
			color: #000000;
		}
	}
}

.select2-container--open .select2-dropdown--below,
.select2-container--open .select2-dropdown--above {
	max-width: 360px;
	border-radius: 5px;
	border: 1px solid #EEEEEE;
}

// .select2-container--open .select2-dropdown--below { margin-top: -40px; }

// .select2-container--open .select2-dropdown--above { margin-top: 40px; }

.select2-results__option {
	padding: 16px;
	color: #000000;
	font-size: 16px;
	border-bottom: 1px solid #F0F0F0;

	img,
	.icones-abasteceai { margin-right: 12px; }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #C7C7C7;
	color: #000000;
}

.input-range {
  -webkit-appearance: none;
	max-width: 380px;
	width: 90%;
	background: none;
}
.input-range:focus {
  outline: none;
}
.input-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: $cor-amarelo;
  border-radius: 4px;
}
.input-range::-webkit-slider-thumb {
	-webkit-appearance: none;
	margin-top: -9.5px;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.3);
  height: 23px;
	width: 23px;
	border-radius: 50%;
  background: $cor-amarelo;
	cursor: pointer;
}
.input-range:focus::-webkit-slider-runnable-track {
  background: $cor-amarelo;
}
.input-range::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: $cor-amarelo;
  border-radius: 2px;
}
.input-range::-moz-range-thumb {
	margin-top: -9.5px;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.3);
  height: 23px;
	width: 23px;
	border-radius: 50%;
  background: $cor-amarelo;
  cursor: pointer;
}
.input-range::-ms-track {
  cursor: pointer;
  background: $cor-amarelo;
  border-radius: 2px;
}
.input-range::-ms-fill-lower {
  cursor: pointer;
  background: $cor-amarelo;
  border-radius: 2px;
}
.input-range::-ms-fill-upper {
  cursor: pointer;
  background: $cor-amarelo;
  border-radius: 2px;
}
.input-range::-ms-thumb {
	margin-top: -9.5px;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.3);
  height: 23px;
	width: 23px;
	border-radius: 50%;
  background: $cor-amarelo;
  cursor: pointer;
}

.input-range:focus::-ms-fill-lower {
  background: $cor-amarelo;
}
.input-range:focus::-ms-fill-upper {
  background: $cor-amarelo;
}