.faq {
  margin-bottom: 55px;
  @include media-min992 { margin-bottom: 95px; }
  
  .card {
    border: 1px solid #EEEEEE !important;
    border-radius: 5px !important;
    background-color: #FFFFFF;
    transition: all 0.4s ease;

    &.open {
      background-color: #F4F6FF;
      .faq-btn {
        padding: 22px 15px 16px;
        @include media-min992 { padding: 28px 88px 16px; }
      }

      .card-body {
        padding: 0 15px 22px;
        @include media-min992 { padding: 0 88px 20px; }
      }
      .card-icon {
        &::before {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
    }

    &-header {
      padding: 0;
      position: relative;
      background: none;
      border: none;

      p {
        position: relative;
        margin: 0;
        display: flex;
        align-items: baseline;
        @include media-min992 { align-items: center; }
      }

      .card-title {
        margin-bottom: 0;
        line-height: 1;
        flex: 1;
      }
    }

    &-icon {
      margin-right: 10px;
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      
      @include media-min992 {
        position: absolute;
        top: 50%;
        left: -38px;
        transform: translateY(-50%);
      }
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 4px;
        background-color: #5982FF;
        transition: all 0.4s ease;
      }

      &::before {
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        left: 50%;
      }
      &::after {
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }

    & + .card { margin-top: 16px; }

    .collapse,
    .collapsing {
      padding: 0;
      
      .card-body {
        padding: 0 15px 18px;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
        color: #717171;

        @include media-min992 { font-size: 16px; padding: 0 88px 28px; }
      }
    }
  }

  &-btn {
    padding: 20px 15px 18px;
    position: relative;
    display: flex;
    width: 100%;
    background: none;
    border: none;
    color: #000000;
    font-family: 'hendersonsans-bold';
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    
    @include media-min992 {
      padding: 28px 88px;
      font-size: 22px;
      line-height: 33px;
    }
    &:hover,
    &:focus {
      outline: none;
      background: none;
      color: #000000;
      box-shadow: none;
    }
  }
}