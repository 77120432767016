.banner {
  background-color: $cor-azul;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 500px;

  @include media-min768  {min-height: 730px; }
  
  @include media-min992 { display: block; }

  @include media-min1200 { min-width: 820px; }

  .container {
    display: flex;
    height: 100%;
    align-items: center;
    @include media-min768 {
      margin-left: 30px;
    }
  
    @include media-min992 { margin-left: auto; }

  }

  .titulo {
    margin-bottom: 16px;
    opacity: 0;
    transform: translateX(-100px);
    animation: slide-in 1s 0.2s ease forwards, fade-in 1s 0.2s ease forwards;

    @include media-min768 {
      margin-bottom: 30px;
    }

    @include media-min992 {
      line-height: 80px;
    }
  }

  .cashback {
    // margin: 0 10px;
    margin-top: -5px;
    max-width: 130px;

    @include media-min768 { max-width: 200px; margin-top: -15px; }
    @include media-min1440 { max-width: 260px; }
  }

  &_logo {
    margin-bottom: 24px;
    align-self: center;
    opacity: 0;
    animation: fade-in 0.8s 0.4s ease forwards;
    
    @include media-min480 { margin-top: -100px; }
    @include media-min768 { margin-bottom: 50px; margin-top: 0; align-self: flex-start; }
    @include media-min992 { margin-bottom: 40px; }
    @include media-min1200 { display: none; margin-bottom: 0; }
  }

  &_texto {
   
    margin-bottom: 8px;
    font-family: 'hendersonsans-light', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    opacity: 0;
    transform: translateX(-100px);
    animation: slide-in 1s 0.4s ease forwards, fade-in 1s 0.4s ease forwards;

    @include media-min768 {
      max-width: 380px;
    }

    @include media-min992 {
      max-width: 440px;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 25px;
    }
    
    @include media-min1200 {
      max-width: 480px;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 25px;
    }
  }

  &_conteudo {
    margin: 0 auto;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 1;

    @include media-min768 {
      margin: 0;
      text-align: left;
      justify-content: start;
      padding-top: 55px;
    }
    
    @include media-min992 {
      margin-bottom: 0;
      justify-content: center;
      padding-top: 0;
    }
    
    @include media-min1200 {
      padding-top: 0;
      justify-content: center;
    }
  }

  &_img {
    
    &-desktop{
      display: none;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0;
      transform: translateX(500px);
      animation: slide-in 1s ease forwards, fade-in 1s ease forwards;

      @include media-min768 {
        display: block;
        max-width: 670px;
        right: -110px;
        bottom: -140px;
      }

      @include media-min992 {
        max-width: 540px;
        right: 0;
        bottom: 0;
      }

      @include media-min1200 { max-width: 680px; }

      @include media-min1440 { max-width: 750px; bottom: -10%; }

      @include media-min1920 {
        bottom: -15%;
        max-width: inherit;
      }
    }

    &-mobile {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      
      @include media-min768 { display: none; }
      
      &.bottom {
        min-width: 754px;
        bottom: -100px;
      }

      &.top {
        top: 0;
        left: 0;
        min-width: 461px;
      }
    }
  }

  &_mais {
    position: absolute;
    bottom: 40px;
    display: none;
    align-items: flex-start;
    font-family: 'hendersonsans-light';
    color: #ffffff;
    font-size: 14px;
    line-height: 21px;
    opacity: 0;
    animation: fade-in 0.8s 0.4s ease forwards;

    @include media-min1200 { display: flex; text-align: left; }

    &-mouse {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-seta {
      margin-top: 4px;
      animation: role-seta 2s infinite;
    }

    p {
      margin-left: 8px;
      margin-bottom: 0;
    }
  }

  &_store {
    display: none;
    position: relative;
    z-index: 2;
    @include media-min992 { display: block; }

    &-mobile {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 2;
      color: #000000;
      transition: none;
      z-index: 5;

      @include media-min992 { display: none; }
    }
    
    .btn-store {
      border-color: #3164FF;

      // img{ max-width: 80%; }
      & + .btn-store {
        margin-left: 10px;
      }
  
      &::before {
        background-color: #3164FF;
      }
    }
  }

}