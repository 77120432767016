.sua-vida_carousel {
  &.owl-carousel {
    .owl-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      
      @include media-min768 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }

      @include media-min992 { bottom: 20px; }
      @include media-min1600 { bottom: 70px; }
  
      .owl-prev,
      .owl-next {
        width: 70px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 50%;
        border: 1px solid #000000;
        color: #000000;
        font-size: 14px;
        transition: opacity 0.4s ease;

        &.disabled {
          opacity: 0.3;
        }
      }
  
      .owl-prev {
        margin-right: 86px;
      }
    }

    .owl-item {
      &.active {
        .item {
          opacity: 1;
          transition-delay: 0.4s;

          .titulo,
          .item_texto,
          .detalhe {
            opacity: 1;
            transform: translate(0);
          }

          .titulo { transition-delay: 0.6s; }
          .item_texto { transition-delay: 0.8s; }
          .detalhe { transition-delay: 0.6s; }
        }
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        opacity: 0;
        transition: all 0.6s ease;
        transition-delay: 0.6s;
        
        @include media-min768 { flex-direction: row; }
        
        .titulo { 
          color: #000000; 
          margin-bottom: 18px;
          transform: translateX(-40px);
          opacity: 0;
          transition: all 0.8s ease;
          transition-delay: 0.4s;
          
          @include media-min768 {
            font-size: 28px;
            line-height: 36px;
          }
        }
        
        &_conteudo {
          margin-top: -30px;
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          text-align: center;

          @include media-min768 { 
            padding-left: 0;
            padding-right: 0;
            margin-right: 40px; 
            margin-top: 0;
            width: 40%; 
            text-align: left; 
          }
        }
  
        &_texto {
          margin: 0 auto 14px;
          max-width: 400px;
          font-size: 14px;
          line-height: 21px;
          color: #5F5F5F;
          opacity: 0;
          transform: translateX(-40px);
          transition: all 0.8s ease;
          transition-delay: 0.6s;
          
          @include media-min768 {
            margin-bottom: 30px;
          }

          @include media-min1200 {
            font-size: 16px;
            line-height: 26px;
          }
        }
  
        &_img {
          width: 100%;
          position: relative;

          @include media-min768 { width: 60%; }

          img {
            max-width: 200px;
            margin: 0 auto;

            @include media-min768 { max-width: 250px; margin: 0; }
            @include media-min992 { max-width: 300px; }
            @include media-min1440 { max-width: 50%; }
            @include media-min1600 { max-width: inherit; }
          }
  
          .detalhe {
            position: absolute;
            max-width: 200px;
            z-index: -1;
            transform: translateX(-60px);
            opacity: 0;
            transition: all 0.8s ease;
            transition-delay: 0.4s;

            @include media-min992 { max-width: inherit; }
            @include media-min1440 { max-width: 50%; }
  
            &.um {
              right: 0;
              bottom: 150px;

              @include media-min480 { right: 90px; }
              @include media-min768 { right: 110px; }
              @include media-min992 { max-width: 350px; right: 140px; }
              @include media-min1200 { max-width: 270px; }
              @include media-min1440 { bottom: 250px; right: 200px; max-width: 50%; }
              @include media-min1600 { bottom: 300px; right: 0; max-width: inherit; }
            }

            &.dois {
              right: 0;
              bottom: 130px;
              max-width: 180px;

              @include media-min480 { right: 90px; }
              @include media-min768 { max-width: 200px; bottom: 200px; }
              @include media-min992 { right: 130px; max-width: 270px; }
              @include media-min1200 { max-width: 220px; }
              @include media-min1440 { max-width: 50%; right: 140px; }
              @include media-min1600 { max-width: inherit; right: 0; }
            }

            &.tres {
              top: 40px;
              left: 120px;

              @include media-min480 { left: 220px; }
              @include media-min768 { left: 120px; }
              @include media-min992 { left: 100px; max-width: 300px; }
              @include media-min1200 { max-width: 250px; }
              @include media-min1440 { left: 80px; max-width: 50%; }
              @include media-min1600 { left: 135px; max-width: inherit; }
            }

            &.quatro {
              top: 40px;
              right: 0;
              max-width: 130px;

              @include media-min480 { right: 100px; }
              @include media-min768 { max-width: 150px; }
              @include media-min992 { max-width: 200px; right: 160px; }
              @include media-min1200 { right: 105px }
              @include media-min1440 { right: 190px; top: 30px; max-width: 50%; }
              @include media-min1600 { right: 110px; max-width: inherit; }
            }

            &.cinco {
              top: 60px;
              left: 50px;
              max-width: 280px;

              @include media-min480 { left: 140px; }
              @include media-min768 { max-width: 380px; left: -60px; }
              @include media-min992 { max-width: 440px; }
              @include media-min1440 { left: -55px; max-width: 70%; }
              @include media-min1600 { left: -75px; max-width: inherit; }
            }

            &.seis {
              top: 80px;
              right: 50px;

              @include media-min480 { right: 120px; }
              @include media-min992 { right: 180px; max-width: 320px; }
              @include media-min1200 { right: 120px; }
              @include media-min1440 { right: 265px; max-width: 50%; }
              @include media-min1600 { right: 125px; max-width: inherit; }
            }
            
            &.sete {
              bottom: 80px;
              right: 0;
              
              @include media-min480 { right: 70px; }
              @include media-min768 { bottom: 120px; }
              @include media-min992 { right: 140px; max-width: 270px; }
              @include media-min1200 { right: 100px; }
              @include media-min1440 { right: 170px; max-width: 50%; }
              @include media-min1600 { right: 50px; max-width: inherit; }
            }
          }
        }
  
        img { width: auto !important; }

        .btn-link {
          display: flex;
          align-items: center;
          justify-content: center;

          @include media-min768 { justify-content: flex-start; }
  
          img,
          .icones-abasteceai { margin-left: 8px; }
        }
      }
    }

  }

  &-counter {
    margin-top: -45px;
    margin-bottom: 20px;
    font-family: 'hendersonsans-bold', sans-serif;
    font-size: 20px;
    line-height: 29px;
    color: #000;
    text-align: center;

    @include media-min992 { margin-top: -70px; }
    @include media-min1600 { margin-top: -120px; }
  }

  &-container {
    padding-left: 0;
    padding-right: 0;
    @include media-min768 {
      padding-left: inherit;
      padding-right: inherit;
    }
  }
}

.owl-carousel .animated  {
  animation-duration: 1s;
  animation-fill-mode: both;
} 

.owl-carousel .out  {
  animation-name: resetAnimation;
}

.sect-parceiros_carousel {
  .owl-item {
    .card-parceiro_link {	

      .card-parceiro {
        max-width: 285px;
        height: 423px;
        padding: 24px;

        &_logo {
          max-width: 69px;
        }
      }
    }
  }

  .owl-dots {

  }
}

@keyframes resetAnimation {
  to {
    z-index: 1;
  }
}