/* Seção de cores background */
$cor-azul-clara: #E1E8FD;
$cor-azul: #5982FF;
$cor-azul-escuro: #3E66EA;

/* Seção de cores botões */
$cor-btn-amarelo: #FFCF00;
$cor-btn-preto: #000000;

/* Seção cores textos */
$cor-azul-piscina: #4CDCFF;
$cor-apoio: #5F5F5F;
$cor-apoio-claro: #AAAAAA;

$cor-amarelo: #FFCF00;