/*!
 * Tipografia
 * @Nome do projeto
 * Desenvolvido por @nome em: 21/10/2016
 */

@font-face {
    font-family: 'hendersonsans-bold';
    src: url('../fonts/hendersonsans/hendersonsansbasic-bold-webfont.eot');
    src: url('../fonts/hendersonsans/hendersonsansbasic-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hendersonsans/hendersonsansbasic-bold-webfont.woff2') format('woff2'),
         url('../fonts/hendersonsans/hendersonsansbasic-bold-webfont.woff') format('woff'),
         url('../fonts/hendersonsans/hendersonsansbasic-bold-webfont.ttf') format('truetype'),
         url('../fonts/hendersonsans/hendersonsansbasic-bold-webfont.svg#hendersonsans-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hendersonsans-regular';
    src: url('../fonts/hendersonsans/hendersonsansbasic-regular-webfont.eot');
    src: url('../fonts/hendersonsans/hendersonsansbasic-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hendersonsans/hendersonsansbasic-regular-webfont.woff2') format('woff2'),
         url('../fonts/hendersonsans/hendersonsansbasic-regular-webfont.woff') format('woff'),
         url('../fonts/hendersonsans/hendersonsansbasic-regular-webfont.ttf') format('truetype'),
         url('../fonts/hendersonsans/hendersonsansbasic-regular-webfont.svg#hendersonsans-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'hendersonsans-light';
    src: url('../fonts/hendersonsans/hendersonsansbasic-light-webfont.eot');
    src: url('../fonts/hendersonsans/hendersonsansbasic-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/hendersonsans/hendersonsansbasic-light-webfont.woff2') format('woff2'),
         url('../fonts/hendersonsans/hendersonsansbasic-light-webfont.woff') format('woff'),
         url('../fonts/hendersonsans/hendersonsansbasic-light-webfont.ttf') format('truetype'),
         url('../fonts/hendersonsans/hendersonsansbasic-light-webfont.svg#hendersonsans-light') format('svg');
    font-weight: normal;
    font-style: normal;
}