/* ==========================================================================
   Geral
   ========================================================================== */


body {
  font-family: 'hendersonsans-regular', sans-serif;
  color: #666666;
  background-color: #FFFFFF;
  overflow-x: hidden;
}

.container,
.container-xl {
  @include media-min1920 {
    max-width: 1440px;
  }
}

.overflow-hidden {
  overflow: hidden !important;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;

  @include media-min992 {
    width: 40px;
    height: 40px;
  }
  
  &-rosa { background-color: #FAB0FF; }
  &-azul { background-color: #41DAFF; }
}

.square {
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  
  @include media-min992 {
    width: 33px;
    height: 33px;
  }

  &-verde { background-color: #8DE87C; }
}

.sect-seja-parceiro {
  position: relative;
  padding-top: 40px;
  background: rgb(81,121,235);
  background: -moz-linear-gradient(0deg, rgba(81,121,235,1) 0%, rgba(62,102,234,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(81,121,235,1) 0%, rgba(62,102,234,1) 100%);
  background: linear-gradient(0deg, rgba(81,121,235,1) 0%, rgba(62,102,234,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5179eb",endColorstr="#3e66ea",GradientType=1);
  overflow: hidden;

  @include media-min768 { padding-top: 80px; }
  @include media-min992 { overflow: hidden; }
  @include media-min1200 { padding-top: 100px;  }

  .titulo { margin-bottom: 30px; }
  &_conteudo{
    padding-bottom: 240px;
    position: relative;
    @include media-min768{
      padding-bottom: 80px;
    }
  }
  &_texto {
    margin-bottom: 62px;
    color: #FFF;
    max-width: 380px;

    @include media-min1200 { max-width: 480px; }
  }

  &_img-mobile {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-30%, 0);
  }

  &_img {
    display: none;
    position: absolute;
    top: 50%;
    right: -18%;
    max-width: 400px;
    transform: translateY(-50%);

    @include media-min768 { 
      display: block;
      transform: inherit;
      position: absolute;
      top: -80px;
      right: -520px;
      max-width: 840px;
    }

    @include media-min992 {
      max-width: 1120px;
      display: block;
      right: -660px;
      top: -80px;
      transform: translateY(0);
    }
    @include media-min1200 {
      max-width: 1120px;
      right: -550px;
      top: -100px;
    }
    @include media-min1440 {
      max-width: 1120px;
      right: -600px;
      top: -100px;
    }
  }
}

.sect-seu-carro{
  padding-top: 34px;
  @include media-min992 { padding-top: 108px; }

  .seu-carro {
    &-lista{
      padding: 0;
      margin: 50px 0;
      display: flex;
      flex-direction: column;

      @include media-min768 {
        margin: 75px 0 100px;
        justify-content: center;
        flex-direction: row;
      }
  
      p {
        margin: 0;
        text-align: left;
        max-width: 180px;
        font-size: 16px;
        color: #000000;
        opacity: 0.60;

        @include media-min768 { 
          text-align: center; 
          max-width: 250px;
          font-size: 14px;
        }
        @include media-min992 { font-size: 16px; }

        @include media-min1600 { font-size: 22px; }
      }
  
      li {
       display: flex;
       align-items: center;
       align-self: flex-start;
       opacity: 0;
       transform: translateY(-40px);

       &.animate {
         animation: slide-in 0.8s ease forwards, fade-in 0.6s ease forwards;
       }
  
       @include media-min768 {
         text-align: center;
         flex-direction: column;
         align-items: center;
         transform: translateX(-60px);
        }
      }

      .divider{
        margin: 16px 0;
        align-self: center;
        justify-content: center;
        @include media-min768 { margin: 0 30px; }
        @include media-min992 { margin: 0 80px; }
      }
    }

    &_img {
      display: flex;
      align-items: center;
      max-width: 80px;
      max-height: 80px;
      margin-right: 18px;

      @include media-min992 {
        margin-right: 0;
        margin-bottom: 22px;
        max-width: inherit;
        min-height: 160px;
      }
    }
  }

  .circulo-rosa{
    width: 40px;
    height: 40px;
    background-color: #FAB0FF;
    border-radius: 100%;
  }

  .losango-verde{
    width: 40px;
    height: 40px;
    background-color: #8DE87C;
    transform: rotate(45deg);
  }
}

.sect-sua-vida {
  @include media-min992 { padding-bottom: 10px; }

  .titulo.h2 {
    text-align: center;
    margin-bottom: 18px;

    @include media-min992 { margin-bottom: 30px; }
  }
}
 
.sect-principais-parceiros {
  padding: 40px 0;
  background-color: #416AEA;
  text-align: center;
  @include media-min768 {
    padding: 60px 0;
  }
  
  @include media-min992 {
    padding: 100px 0 80px;
  }

  &_wrapper {
    position: relative;
    margin: 0 20px 30px;
    padding: 15px 0 15px 15px;
    width: 80%;
    border-radius: 54px;
    border: 1px solid #FFFFFF;
    overflow: hidden;
    @include media-min992 { 
      margin: 10px 20px 50px;
      padding-right: 60px;
    }

    img {
      width: auto;
      max-width: inherit;
      max-height: 45px;

      @include media-min992 {
        max-height: inherit;
      }
    }
  }

  .circle,
  .square {
    display: none;
    @include media-min992 { display: block; }
  }

  &_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_texto {
    margin-top: 28px;
    margin-bottom: 0;
    font-family: 'hendersonsans-bold', sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    opacity: 0.63;
    
    @include media-min992 {
      margin-top: 38px;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .parceiros-list {
    margin: 0;
    display: flex;
    align-items: center;

    li {
      min-width: 250px;
      @include media-min992 { min-width: 300px; }
    }
  }
}

.sect-video {
	position: relative;
	padding: 40px 0 45px;
	background-color: #323232;
	width: 100%;
	overflow: hidden;

	@include media-min992 { padding: 100px 0 100px; }

	.container { position: relative; }

	.titulo {
		color: $cor-azul-piscina;
		margin-bottom: 40px;

		@include media-min992 { margin-bottom: 47px; }
	}

	&_iframe {
    border: none;
    @include media-min768 {
			width: 620px;
			height: 360px;
		}

		@include media-min992 {
			width: 944px;
			height: 545px;
		}
	}

	&_img {
		position: absolute;
    display: block;
    max-width: 27%;

		&.left {
			bottom: 0;
			left: 0;
		}

		&.right {
			bottom: 10%;
			right: 0;
		}
		
		@include media-min1200 { display: block; }
	}
}

.sect-parceiros {
  padding-top: 39px;
  padding-bottom: 37px;
  @include media-min992 {
    padding-top: 53px;
    padding-bottom: 64px;
  }

	.titulo.h2 {
    margin-bottom: 40px;
    @include media-min992 {
      max-width: 742px;
      margin-bottom: 55px;
    }
	}
	
	.btn.btn-link {
    font-size: 16px;
    color: #416AEA;
    max-width: 208px;
    margin: 32px auto 0;
    
    @include media-min992 {
      margin: 40px 0 0;
      max-width: inherit;
      font-size: 24px;
      line-height: 29px;
    }

		&:hover {
			color: darken(#416AEA, 10%);
			transition: all 0.3s ease;
		}
	}
}

.sect-facil-aproveitar{
    background-color: $cor-azul-clara;
    padding-top: 40px;
    padding-bottom: 10px;
    @include media-min768 {
      padding-top: 80px;
      padding-bottom: 40px;
    }
    @include media-min992 {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .passos{
      list-style: none;
      margin: 50px 0 20px 0;
      padding: 0;
      @include media-min992 {
        margin: 65px 0 0 0;
      }
      li{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &:last-of-type{ margin-bottom: 0; }
        @include media-min768 {
          align-items: flex-start;
        }
        @include media-min992 {
          margin-bottom: 100px;
        }
      }
      &-num{
        background-color: #7A9AFF;
        color: #FFFFFF;
        font-size: 30px;
        font-family: 'hendersonsans-bold';
        width: 50px;
        height: 50px;
        padding-top: 3px;
        margin-right: 20px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-min992 {
          width: 65px;
          height: 65px;
          font-size: 30px;
          margin-right: 30px;
        }
      }
      &-info{
        flex: 1;
        p{
          color: #000000;
          font-size: 16px;
          font-family: 'hendersonsans-bold';
          margin-bottom: 0;
          @include media-min768 { margin-bottom: 1rem; }
          @include media-min992 { font-size: 28px; }
        }
      }
      &-download{
        width: calc(100% - 170px);
        position: relative;
        z-index: 2;
      }
    }
}

.sect-duvidas {
  padding: 37px 0 62px;

  @include media-min992 { padding: 118px 0 114px; }

  .titulo {
    @include media-min992 {
      margin-bottom: 40px;
    }
  }

  &_cta {
    display: inline-block;
    font-family: 'hendersonsans-bold', sans-serif;
    font-size: 16px;
    color: #AAAAAA;
    line-height: 29px;
    
    @include media-min992 { 
      text-align: center;
      font-size: 24px;
      margin-top: 59px;
    }
    
    .btn.btn-link {
      color: #5982FF;
      font-size: 16px;
      @include media-min992 { font-size: 24px; }

      &:hover {
        color: #5982FF;
      }
    }

    &-seta {
      margin-left: 8px;
      max-width: 38px;
      @include media-min992 { margin-left: 26px; max-width: inherit; }
    }
  }
}

.sugestao {
  position: relative;
  padding: 60px 30px 44px;
  margin-bottom: 52px;
  background-color: $cor-azul;
  margin-top: 50px;
  transition: all 0.4s ease;
  @include media-min768 {
    margin-top: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 0;
  }
  
  @include media-min992 {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 50px;
    padding-right: 0;
    margin-bottom: 175px;
  }
  @include media-min1200 {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 175px;
  }

  .row { align-items: center; }

  .titulo { color: #FFFFFF; transition: color 0.4s ease; }

  &_texto {
    margin: 25px 0 0;
    max-width: 490px;
    color: #FFFFFF;
    transition: color 0.4s ease;
    font-size: 14px;
    @include media-min992 { font-size: 16px; }
  }

  &_img {
    margin-top: -130px;
    margin-left: -50px;
    max-width: 230px;
    transition: all 0.4s ease;
    @include media-min768 {
      max-width: 100%;
      position: absolute;
      width: 50%;
      right: 3%;

      margin-top: 0;
      margin-left: 0; 
    }
    @include media-min992 {
      margin-bottom: -100px;
      position: absolute;
      width: 50%;
      right: 3%;
    }

    @include media-min1200 {
      margin-bottom: -160px;
      position: absolute;
      right: 5%;
      width: 50%;
    }
  }

  &_form {
    display: none;
    margin-top: 25px;
  }

  .btn-block {
    margin-top: 35px;
    @include media-min768 { max-width: 325px; }
  }

  &.active {
    background-color: transparent;
    @include media-min992 { margin-bottom: 75px; }

    .titulo { color: $cor-azul; }
    .sugestao_texto { color: #000000; }
    .sugestao_img {
      @include media-min992 { margin-bottom: -60px; }
    }
  }
}

.sect-download {
  overflow: hidden;
  position: relative;
  padding-bottom: 205px;

  @include media-min768 {
    overflow: initial;
    padding: 95px 0;
  }

  @include media-min992 {
    padding: inherit;
  }

  &_container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-min992 {
      flex-direction: row;
      align-items: center;
    }
  }

  &_imagem {
    position: absolute;
    bottom: 0;
    right: -40px;
    max-height: 282px;

    @include media-min768 {
      right: 40px;
      max-height: 382px;
    }

    @include media-min992 {
      position: inherit;
      max-height: inherit;
    }
  }

  &_conteudo {

    @include media-min992 {
      margin-right: 89px;
    }

    @include media-min1200 {
      margin-right: 189px;
    }

    .titulo.h2 {
      margin-bottom: 11px;

      @include media-min1600 {
        font-size: 50px;
        line-height: 60px;
      }
    }

    .container-store {
      margin-top: 26px;
      display: flex;
      flex-direction: column;

      @include media-min992 {
        flex-direction: row;
      }

      .btn {
        max-width: 171px;

        & + .btn {
          margin-top: 15px;

          @include media-min992 {
            margin-top: 0;
          }
        }

        @include media-min768 {
          max-width: 178px;
        }

        @include media-min992 {
          width: inherit;
        }
      }

      @include media-min992 {
        margin-top: 75px;
      }
    }

    .sect-apoio {
      margin: 0;
      font-family: 'hendersonsans-light', sans-serif;
      font-size: 16px;
      line-height: 19px;
      color: #5982FF;

      @include media-min992 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
}