.modal-parceiro {
  padding: 20px;
  display: none;
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FAFAFA;
  transform: translateX(100%);
  @include media-min768 { padding: 50px; }

  @include media-min992 { padding: 78px; }
  
  &.open {
    display: block;
    animation: slide-in 0.8s ease forwards;
  }

  &_img {
    position: absolute;
    display: none;
    top: 0;
    right: -5%;
    max-width: 40%;
    z-index: -1;
    @include media-min992 {
      max-width: inherit;
      display: block;
      width: 45%;
      right: -5%;
      max-width: 815px;
    }
    @include media-min1200 {
      right: 0;
    }
   
  }

  .titulo { margin-bottom: 20px; }

  &_texto {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 24px;

    @include media-min992 {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &_btn {
    margin-bottom: 32px;
    font-family: 'hendersonsans-bold', sans-serif;
    line-height: 26px;
    
    img {
      margin-top: -4px;
      margin-right: 16px;
    }
  }

  button[type="submit"] {
    padding-left: 22px;
    padding-right: 22px;
    font-size: 14px;
    @include media-min992 { font-size: 16px; padding-left: 32px; padding-right: 32px; }
  }

  &.modal-out {
    display: block;
    transform: translateX(0);
    animation: modal-out 0.8s ease forwards;
  }
}