/*!
 * Abastece Aí
 * Desenvolvido por Fattoria em: 20/07/2020
 */
 
 // Config
 @import "../sass/config/_variaveis.scss";
 @import "../sass/config/_mixings.scss";
 @import "../sass/config/_placeholder.scss";
 @import "../sass/config/_cores.scss";

// Libs
@import "../sassLibs/bootstrap/bootstrap.scss";
@import "../sassLibs/fontAwesome/fontawesome.scss";
@import "../sassLibs/fontAwesome/brands.scss";
@import "../sassLibs/fontAwesome/regular.scss";
@import "../sassLibs/fontAwesome/solid.scss";
@import "../sassLibs/fontAwesome/v4-shims.scss";


// Tipografia
@import "../sass/_tipografia.scss";

// Geral
@import "../sass/geral/_reset.scss";
@import "../sass/geral/_medias.scss";
@import "../sass/geral/_utilidades.scss";
@import "../sass/geral/_animations.scss";
@import "../sass/geral/_geral.scss";

// Componentes
@import "../sass/componentes/_btns.scss";
@import "../sass/componentes/_titulos.scss";
@import "../sass/componentes/_cards.scss";
@import "../sass/componentes/_forms.scss";
@import "../sass/componentes/_header.scss";
@import "../sass/componentes/_banner.scss";
@import "../sass/componentes/_modais.scss";
@import "../sass/componentes/_footer.scss";
@import "../sass/componentes/_carousel.scss";
@import "../sass/componentes/_faq.scss";

// Paginas
// @import "../sass/paginas/_componentes-exemplo.scss";
@import "../sass/paginas/_perguntas-frequentes.scss";