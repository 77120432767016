.titulo {
    font-family: 'hendersonsans-bold', sans-serif;
    color: #5982FF;
    margin-top: 0;
    margin-bottom: 0;

    &.h1 {
        font-size: 23px;
        line-height: 28px;

        @include media-min480 {
            font-size: 30px;
            line-height: 40px;
        }

        @include media-min768 {
            font-size: 40px;
            line-height: 62px;
        }

        @include media-min992 {
            font-size: 50px;
            line-height: 62px;
        }
    }

    &.h2 {
        font-size: 22px;
        line-height: 26px;

        @include media-min768 {
            font-size: 30px;
            line-height: 42px;
        }

        @include media-min992 {
            font-size: 40px;
            line-height: 51px;
        }

        @include media-min1600 {
            font-size: 59px;
            line-height: 71px;
        }
    }

    &.h3 {
        font-size: 22px;
        line-height: 26px;

        @include media-min768 {
            font-size: 24px;
            line-height: 32px;
        }

        @include media-min992 {
            font-size: 30px;
            line-height: 56px;
        }

        @include media-min1440 {
            font-size: 50px;
            line-height: 76px;
        }
    }

    &.h4 {
        font-size: 18px;
        line-height: 22px;

        @include media-min768 {
            font-size: 30px;
            line-height: 32px;
        }

        @include media-min992 {
            font-size: 40px;
            line-height: 48px;
        }
    }

    &.h5 {
        font-size: 17px;
        line-height: 23px;

        @include media-min992 {
            font-size: 22px;
            line-height: 28px;
        }
    }

    &.h6 {
        font-size: 16px;
        line-height: 21px;

        @include media-min992 {
            font-size: 22px;
            line-height: 26px;
        }
    }
}