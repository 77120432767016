.header {
  padding: 0 36px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
  transition: all 0.4s ease;
  z-index: 9;
  background-color: #FFFFFF;
  transform: translateX(100%);
  
  @include media-min1200 {
    padding-top: 55px;
    padding-bottom: 20px;
    position: absolute;
    max-height: inherit;
    height: auto;
    overflow: unset;
    background: none;
    transform: translateX(0);
  }

  .container {
    padding-top: 64px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    flex-direction: column;

    @include media-min992 { padding-top: 83px; }
    
    @include media-min1200 {
      padding-top: 0;
      padding-bottom: 0;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @include media-min992 {
      align-items: center;
    }

    @include media-min1200 { width: auto; }
  }

  &_logo {
    align-self: flex-start;
    transition: all 0.6s ease;
    max-width: 130px;
    margin-top: -5px;

    &.desktop {
      display: none;
      @include media-min1200 { display: block; }
    }

    &.scroll { display: none; }

    @include media-min992 { opacity: 1; max-width: 60px; }
    @include media-min1200 { max-width: inherit; }
  }

  &-nav {
    display: flex;
    flex-direction: column;

    @include media-min992 { margin-top: 0; }

    @include media-min1200 { display: block; }

    .header_logo {
      margin-bottom: 68px;
      max-width: 60px;

      @include media-min768 { max-width: inherit; }
      @include media-min1200 { display: none; }
    }

    &_img {
      position: absolute;
      top: 0;
      right: 0;

      @include media-min768 { right: 30%; }
      @include media-min1200 { display: none; }
    }

    &_lista {
      margin: 0 0 40px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #EBEBEB;

      @include media-min1200 { 
        margin: 0;
        flex-direction: row;
        align-items: center;
        border: none;
      }
    }

    &_item {
      margin-bottom: 45px;
      font-size: 16px;
      // line-height: 26px;
      color: $cor-azul;
      font-family: 'hendersonsans-bold', sans-serif;

      @include media-min1200 { 
        margin-right: 45px;
        margin-bottom: 0;
        color: #FFFFFF;
        font-family: 'hendersonsans-regular', sans-serif;
      }
      @include media-min1440 { margin-right: 75px; }

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-color: #FFFFFF;
        transition: all 0.4s ease;
      }

      &:hover,
      &.active {
        &::after { width: 100%; }
      }

      a:hover { color: inherit; }

      &:last-of-type { 
        margin-bottom: 58px;
        @include media-min1200 { margin-bottom: 0; }
      }

    }
    
    &_btn {
      display: none;

      @include media-min1200 { display: block; margin: 0; }
    }

    &_toggle {
      padding: 4px;
      position: fixed;
      top: 40px;
      right: 20px;
      width: 58px;
      height: 58px;
      background: none;
      border: none;
      border-radius: 50%;
      opacity: 1;
      transition: all 0.4s ease;
      z-index: 9;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      @include media-min1200 {  display: none; }

      &.active { opacity: 0; }
      &.scrolled { background-color: $cor-azul; }
      
      .line {
        position: absolute;
        left: 0;
        display: block;
        width: 30px;
        height: 3px;
        border-radius: 10px;
        background-color: #FFFFFF;
        transition: all 0.4s;

        &-wrapper {
          margin: 0 auto;
          position: relative;
          width: 30px;
          height: 19px;
        }
        
        &:nth-of-type(1){
          top: 0;
          transition-delay: 0.2s;
        }

        &:nth-of-type(2){
          top: 8px;
          transition-delay: 0.2s;
        }

        &:nth-of-type(3){ bottom: 0; }

        &.circle {
          width: 20px;

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -5px;
            display: block;
            width: 3px;
            height: 3px;
            background-color: #FAB0FF;
            border-radius: 50%;
            transition: all 0.4s ease;
          }
        }
      }
    }

    &_sociais {
      display: flex;

      li {
        margin-right: 46px;
        color: #000;
        font-size: 16px;

        @include media-min768 { font-size: 18px; }

        a:hover { color: inherit; }
      }

      @include media-min1200 { display: none; }
    }

    .btn-voltar {
      margin-bottom: 68px;
      align-self: flex-start;

      @include media-min1200 { display: none; }
    }
  }

  &.scrolled {
    padding-top: 20px;
    position: fixed;
    background-color: $cor-azul;
    max-height: 60px;
    opacity: 0;
    transform: translateY(-100%);
    animation: slide-in 0.6s ease forwards, fade-in 0.8s ease forwards;

    @include media-min992 { max-height: 100px; }

    @include media-min1200 { max-height: inherit; }

    .header_logo {
      opacity: 0;
      animation: fade-in 0.6s ease forwards;

      &.desktop { display: none; }

      &.scroll { display: block; }
    }

    .header-nav_item {
      @include media-min1200 {
        margin-right: 35px;
        font-size: 16px;
      }

      @include media-min1920 {
        margin-right: 75px;
      }
    }
  }
  
  &.active {
    transform: translateX(0);

    &.scrolled {
      padding-top: 64px;
      @include media-min992 { padding-top: 20px; }
    }

    .header-nav_toggle {
      .line {
        &:nth-of-type(1){
          top: 0;
          transform: rotate(45deg);
        }
        &:nth-of-type(2){
          top: 0;
          transform: rotate(-45deg);
        }

        &:nth-of-type(3){
          opacity: 0;
          width: 0;
          &::after { transform: scale(0); }
        }
      }
    }
  }
}